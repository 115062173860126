import React from 'react';

import ProductoDetail from '../components/producto/ProductoDetail';

const BASE_URL = (process.env.NODE_ENV === 'development') 
                  ? 'http://localhost:3000' : process.env.REACT_APP_PATH;


export default function Producto() {   

    return <section className="producto__detail">
        <ProductoDetail
            baseUrl={BASE_URL}
        />
    </section>
}
