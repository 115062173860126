import React from 'react'
import {
    Link
} from 'react-router-dom';

export default function NavBar() {

    return (
        <nav className="nav d-flex justify-content-between align-items-center">
            <Link 
                to="/producto/ms-170"
                className="nav__logo"
            />
            <div>
                <ul className="nav__list">
                    <li className="nav__item">
                        <a 
                            title="Stihl en Twitter"
                            className="nav__link nav__icon nav__icon-tw"
                            target="_blank" 
                            href="https://twitter.com/stihlmexico"
                            rel="noreferrer"
                        >
                            <span className="nav__link--hidden">Twitter</span>
                        </a>
                    </li>
                    <li className="nav__item">
                        <a 
                            title="Stihl Instagram"
                            className="nav__link nav__icon nav__icon-ig" 
                            target="_blank"
                            href="https://www.instagram.com/stihlmexico/"
                            rel="noreferrer"
                        >
                            <span className="nav__link--hidden">Instagram</span>
                        </a>
                    </li>
                    <li className="nav__item">
                        <a 
                            title="Stihl en Facebook"
                            className="nav__link nav__icon nav__icon-fb" 
                            target="_blank"
                            href="https://www.facebook.com/STIHLMEXICO"
                            rel="noreferrer"
                        >
                            <span className="nav__link--hidden">Facebook</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}