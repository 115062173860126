/* eslint-disable array-callback-return */
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import Context from "../../context/ProductosContext";
import useCategoria from "../../hooks/useCategoria";

const BASE_URL = process.env.REACT_APP_PATH;

export default function Datos({
  id,
  categoria,
  imagen,
  anterior,
  siguiente,
  producto,
}) {
  const history = useHistory();

  const { categorias } = useContext(Context);
  const { productByCat } = useCategoria();

  const handleSelect = ({ target }) => {
    const producto = productByCat(target.value);
    if (producto) history.push(`/producto/${producto.slug}`);
  };

  const servicios = () => {
    if (producto) {
      return producto.servicios?.split(".").map((item, i) => {
        if (item !== "") {
          return (
            <li key={item} className="producto__desc mt-1 mb-2">
              {item}
            </li>
          );
        }
      });
    }
  };

  const tableData = () => {
    if (producto) {
      return producto.datos_tecnicos?.split(";")?.map((item, i) => {
        const datos = item.split(",");
        if (datos[0] === "") return null;
        return (
          <tr key={datos[0]}>
            <td
              className="producto__table-td producto_title"
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {datos[0]}
            </td>
            <td
              className="producto__table-td producto_title"
              style={{
                borderLeft: "1px solid #F27A1F",
                borderRight: "1px solid #F27A1F",
                borderBottom:
                  i + 1 !== producto.datos_tecnicos?.split(";").lenght
                    ? "none"
                    : "1px solid #F27A1F",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {datos[1]}
            </td>
            <td className="producto__table-td producto__desc">{datos[2]}</td>
          </tr>
        );
      });
    }
  };

  return (
    <div className="row producto">
      <div className="col-md-6">
        <div className="producto__detalle">
          <select
            className="producto__select form-control"
            value={categoria.slug}
            onChange={handleSelect}
          >
            {categorias.map(({ id, nombre, slug }) => (
              <option key={id} value={slug}>
                {nombre}
              </option>
            ))}
          </select>
          <div key={producto.modelo} className="producto_bg_orange">
            <Link
              className="producto__btn producto__btn-back"
              to={`/producto/${anterior}`}
            />
            <h1 className="producto__modelo text-center">
              {producto.modelo.trim()}
            </h1>
            <Link
              className="producto__btn producto__btn-next"
              to={`/producto/${siguiente}`}
            />
          </div>

          <div
            className="px-4 pb-4"
            style={{
              backgroundColor: "rgb(232 232 232)",
            }}
          >
            {producto?.publico_dirigido && (
              <>
                <h1 className="producto_title pt-3">
                  Publico al que va dirigido
                </h1>
                <div className="producto__desc animacion">
                  {producto.publico_dirigido?.split(".")?.map((item, i) => {
                    if (item !== "") {
                      return (
                        <li key={i} className="producto__desc mt-1 mb-2">
                          {item}
                        </li>
                      );
                    }
                  })}
                </div>
              </>
            )}
            {/*<h1 className="producto_title mt-3">Mercado</h1>
          <div className="mt-2">
            <CasaSvg />
              </div>*/}

            {producto?.aplicaciones && (
              <>
                <h1 className="producto_title pt-3">Aplicaciones</h1>
                <div key={id} className="producto__desc animacion">
                  {producto.aplicaciones?.split(".").map((item, i) => (
                    <p key={i} className="producto__desc mt-2 mb-0">
                      {item}
                    </p>
                  ))}
                </div>
              </>
            )}
          </div>

          {producto.imagen_left1 && (
            <img
              className="img-fluid imagen_left"
              src={`${BASE_URL}img/${producto.imagen_left1}`}
              alt={producto.modelo}
            />
          )}

          {producto.imagen_left2 && (
            <img
              className="img-fluid imagen_left mt-3"
              src={`${BASE_URL}img/${producto.imagen_left2}`}
              alt={producto.modelo}
            />
          )}
        </div>
      </div>
      <div className="col-md-6 pt-5">
        <h5 className="producto_title">
          <span>Descripción</span>
        </h5>
        <p className="producto__desc animacion">{producto.descripcion}</p>
        <img
          width="400"
          className="img-fluid"
          src={`${BASE_URL}img/${imagen}`}
          alt={producto.modelo}
        />

        <h5 className="producto_title mt-5">
          <span>PRINCIPALES ATRIBUTOS</span>
        </h5>
        <div className="producto__desc animacion">
          <ol
            style={{
              padding: "0px 20px",
            }}
          >
            {producto?.principales_atributos?.split(".").map((item, i) => {
              const firstText = item.split(" ").slice(0, 1).join(" ");
              const restText = item.split(" ").slice(1).join(" ");
              // add uppercase resttext first letter
              const restTextUppercase =
                restText.charAt(0).toUpperCase() +
                restText.slice(1).toLowerCase();

              if (item === "") return null;
              return (
                <li key={firstText + i} className="producto__desc mt-2 mb-2">
                  {restTextUppercase}
                </li>
              );
            })}
          </ol>
        </div>

        {producto?.beneficios && (
          <h5 className="producto_title mt-5">
            <span>BENEFICIOS</span>
          </h5>
        )}

        <div key={id} className="producto__desc animacion">
          {producto.beneficios?.split(".").map((item, i) => (
            <p key={i} className="producto__desc mt-2 mb-0">
              {item}
            </p>
          ))}
        </div>

        <h5 className="producto_title mt-5">
          <span>DATOS TÉCNICOS</span>
        </h5>

        {/* table */}
        <div className="table-responsive mt-3">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th
                  scope="col"
                  style={{
                    backgroundColor: "#F27A1F",
                    color: "#fff",
                    border: "1px solid #F27A1F",
                    fontSize: "1rem",
                  }}
                  className="producto_title"
                >
                  STIHL {producto.modelo}
                </th>
                <th
                  scope="col"
                  className="producto_title"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  MEDIDA DEL MERCADO
                </th>
              </tr>
            </thead>
            <tbody>{tableData()}</tbody>
          </table>
        </div>

        {producto?.accesorios && (
          <h1 className="producto_title mt-3">ACCESORIOS</h1>
        )}

        <div className="row">
          {producto?.accesorios?.split(";")?.map((item, i) => {
            const accesorio = item.split(",");
            if (accesorio[0] === "") return null;
            return (
              <div
                key={i}
                className="col-md-6 producto__accesorio_right producto__accesorio_bottom"
              >
                <div className="px-2">
                  <img
                    className="img-fluid"
                    src={`${BASE_URL}img/${accesorio[0].trim()}`}
                    alt={accesorio[1]}
                  />
                  <p className="producto__accesorio-bg">{accesorio[1]}</p>

                  <p
                    className="producto__table-td"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    {accesorio[2] && accesorio[2]}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="col-md-12 mt-5">
        {producto?.atributos_beneficios && (
          <h1 className="producto_title mt-3">ATRIBUTOS Y BENEFICIOS</h1>
        )}

        <div className="row">
          {producto?.atributos_beneficios?.split(";")?.map((item, i) => {
            const accesorio = item.split(",");
            if (accesorio[0] === "") return null;
            return (
              <div
                key={i}
                className="col-md-3 producto__accesorio_right producto__accesorio_bottom"
              >
                <div className="px-2">
                  <img
                    className="img-fluid"
                    src={`${BASE_URL}img/${accesorio[0].trim()}`}
                    alt={accesorio[1]}
                  />
                  <p className="producto__accesorio-bg">{accesorio[1]}</p>

                  {accesorio[2] && (
                    <>
                      <p
                        className="producto__table-td producto_title"
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        atributo
                      </p>
                      <p
                        className="producto__table-td"
                        style={{
                          fontSize: "1rem",
                        }}
                      >
                        {accesorio[2]}
                      </p>
                    </>
                  )}
                  {accesorio[3] && (
                    <>
                      <p
                        className="producto__table-td producto_title"
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Beneficios
                      </p>
                      <p
                        className="producto__table-td"
                        style={{
                          fontSize: "1rem",
                        }}
                      >
                        {accesorio[3]}
                      </p>
                      <p
                        className="producto__table-td"
                        style={{
                          fontSize: "1rem",
                        }}
                      >
                        {accesorio[4] && accesorio[4]}
                      </p>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="col-md-12 pb-5 d-flex justify-content-center">
        {producto?.url_video && (
          <iframe
            width="900"
            height="400"
            src={"https://www.youtube.com/embed/qH9n4UB3IMk"}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        )}
      </div>

      <div className="col-md-12">
        {producto?.uso_seguro && <h1 className="producto_title">USO SEGURO</h1>}
        <div className="row mt-4">
          {producto?.uso_seguro?.split(";")?.map((item, i) => {
            const accesorio = item.split(",");
            if (accesorio[0] === "") return null;
            return (
              <div key={i} className="col-md-3">
                <div className="px-2">
                  <img
                    className="img-fluid"
                    src={`${BASE_URL}img/${accesorio[0].trim()}`}
                    alt={accesorio[1]}
                  />
                  <p className="producto__accesorio-bg">{accesorio[1]}</p>
                  <p
                    className="producto__table-td"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    {accesorio[2] && accesorio[2]}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-md-12">
        {producto?.servicios && (
          <>
            <h5 className="producto_title mt-5">
              <span>SERVICIO Y TIPS</span>
            </h5>
            <ul
              key={id}
              className="producto__desc animacion"
              style={{ listStyle: "square" }}
            >
              {servicios()}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}
