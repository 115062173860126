import React from 'react'
import AppRouter from './router/AppRouter'

export default function App() {
    return (
        <AppRouter />
    )
}

/*
    Steps deploy
    1.- package.json : "homepage": "https://albericlientes.com/landing-stihl-qr",
    2.- Router : basename="/landing-stihl-qr"
    3.- app.scss : change $url variable
*/