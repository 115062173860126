import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
  
import { ProductosContextProvider } from '../context/ProductosContext';
  
import Producto from '../pages/Producto';

import Layout from '../components/layout/Layout';
import NotFound from '../components/layout/NotFound';

export default function AppRouter() {
    return (
        <div>
            <Router basename="/"> {/* basename="/landing-stihl-qr" */}
                <Layout>
                    <ProductosContextProvider>
                        <Switch>
                        <Route exact path="/">
                            <Redirect to="/producto/ms-170" />
                        </Route>
                        <Route path="/producto/:slug" children={<Producto />} />
                        <Route path="*">
                            <NotFound />
                        </Route>
                        </Switch>
                    </ProductosContextProvider>
                </Layout>
            </Router>
        </div>
    )
}