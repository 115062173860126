import React from 'react';
import {
    Link
} from 'react-router-dom';

export default function Footer() {

    return (
        <footer>
            <nav className="footer d-flex justify-content-between align-items-center">
                <Link 
                    to="/producto/ms-170"
                    className="footer__logo"
                />
                <div>
                    <ul className="footer__list">
                        <li className="footer__item">
                            <a 
                                title="Stihl en Twitter"
                                className="footer__link footer__icon footer__icon-tw"
                                target="_blank" 
                                href="https://twitter.com/stihlmexico"
                                rel="noreferrer"
                            >
                                <span className="footer__link--hidden">Twitter</span>
                            </a>
                        </li>
                        <li className="footer__item">
                            <a 
                                title="Stihl Instagram"
                                className="footer__link footer__icon footer__icon-ig"
                                target="_blank"
                                href="https://www.instagram.com/stihlmexico/"
                                rel="noreferrer"
                            >
                                <span className="footer__link--hidden">Instagram</span>
                            </a>
                        </li>
                        <li className="footer__item">
                            <a 
                                title="Stihl en Facebook"
                                className="footer__link footer__icon footer__icon-fb"
                                target="_blank"
                                href="https://www.facebook.com/STIHLMEXICO"
                                rel="noreferrer"
                            >
                                <span className="footer__link--hidden">Facebook</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </footer>
    )
}