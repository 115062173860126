import React, {useState} from 'react';

const Context = React.createContext({})

export function ProductosContextProvider ( { children } ) {

  const [productos, setProductos] = useState([]);
  const [categorias, setCategorias] = useState([]);

  return <Context.Provider value={{productos, setProductos, categorias, setCategorias}}>
    {children}
  </Context.Provider>
}

export default Context