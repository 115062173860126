import { useContext } from 'react';
import Context from '../context/ProductosContext';

export default function useCategoria() {

    const { productos } = useContext(Context);

    const productByCat = (cat_slug) => productos.find(( { categoria } ) => categoria.slug === cat_slug);

    return {
        productByCat
    }
}