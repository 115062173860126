import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import useProductos from "../../hooks/useProductos";
import NotFound from "../layout/NotFound";

import Datos from "../producto/Datos";
import Loader from "../layout/Loader";

export default function ProductoDetail({ baseUrl }) {
  const { slug } = useParams();

  const { producto, isLoading } = useProductos({ slug });

  if (isLoading) return <Loader />;

  if (!producto) return <NotFound />;

  return (
    <>
      <Helmet>
        <title>{producto.title} - STIHL</title>
      </Helmet>
      {producto && (
        <Datos
          id={producto.id}
          modelo={producto.modelo}
          categoria={producto.categoria}
          imagen={producto.imagen}
          descripcion={producto.descripcion}
          caracteristicas={producto.caracteristicas_principales}
          aplicacion={producto.aplicaciones}
          siguiente={producto.siguiente}
          producto={producto}
          anterior={producto.anterior}
          baseUrl={baseUrl}
        />
      )}
    </>
  );
}
