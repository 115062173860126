import React from 'react';

import Footer from './Footer';
import NavBar from './NavBar';

export default function Layout({ children }) {
    return (
        <div className="container">
            <NavBar />
            { children }
            <Footer />
        </div>
    )
}