import { useState, useEffect, useContext } from 'react';

import Context from '../context/ProductosContext';
import { getProductos } from '../services/api';

export default function useProductos({ slug }) {

    const [ isLoading, setIsLoading ] = useState(false);
    const { productos, setProductos, setCategorias } = useContext(Context);

    const producto = productos.find(producto => producto.slug === slug);

    useEffect(() => {

        setIsLoading(true);
        
        getProductos().then(( { data : { productos, categorias } } ) => {
            setProductos(productos);
            setCategorias(categorias);
            setTimeout(() => setIsLoading(false), 1000);
        }).catch(() => {
            alert('Ocurrio un error al obtener la información');
            setIsLoading(false);
        });
    }, [ setProductos, setCategorias ]);

    return {
        producto,
        isLoading
    }
}